import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Fuse from "fuse.js";
import nextArrow from "../../assets/images/nextArrow.png";
import editBtn from "../../assets/images/edit.png";
import deleteBtn from "../../assets/images/deleteBtn.png";
import UserForm from "../UserForm";
import { useDispatch, useSelector } from "react-redux";
import { applicationIdType } from "../../constants/applicationConstants";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { updateUserPhone } from "../../features/users/userSlice";
import { convertTimestampToSeconds, sumOFHoursWorked } from "../UserModules";

const searchOptions = {
    includeScore: true,
    keys: ["first_name"],
  };

const Allusers = ({searchInput, handleShowUser }) => {
    const [showUserForm, setShowUserForm] = useState(false);
    const [dataVisible, setDataVisible] = useState([]);
    const usersList = useSelector((state) => state.users.usersList);
    const allSessions = useSelector(state => state.sessions.allSessions)
    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const [editable, setEditable] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const applicationId = useSelector(state => state.details.applicationId)
    const dispatch = useDispatch()
    const fuseRef = useRef(null);

    useEffect(() => {
        if (usersList && usersList.length) {
          fuseRef.current = new Fuse(usersList, searchOptions);
          setDataVisible(usersList);
        }
      }, [usersList]);

      useEffect(() => {
        if (searchInput && searchInput.length > 0 && fuseRef.current) {
          const result = fuseRef.current.search(`${searchInput}`);
    
          setDataVisible(result.map((rs) => rs.item));
        } else {
          setDataVisible(usersList);
        }
      }, [searchInput]);

    const handleUserForm = () => {
        setShowUserForm((prev) => !prev);
    };

    const handlePhoneUpdate = (e) => {
        if (e.target.value.length < 3) {
            return
        }
        setPhoneNumber(e.target.value)
    }

    const handleUpdatePhoneNumber = (user) => {
        if (phoneNumber.length !== 13) {
            return
        }
        setLoading(true)
        let obj = {
            phoneNumber,
            userId: user.uid
        }
        let cloudRef = httpsCallable(functions,
            "updateUserPassword"
        )
        cloudRef(obj).then(res => {
            if (res) {
                let _user = { ...user }
                _user.phoneNumber = phoneNumber
                dispatch(updateUserPhone({ user: _user }))
                handleEditable(null, null)
                setLoading(false)
            } else {
                setLoading(false)
                console.log("error")
            }
        })
    }

    const getTotalTimeTaken = (user) => {
        let time1 = null;
        let time2 = null
        let totalTime = null
        if (allSessions && allSessions[user.uid]) {
            Object.values(allSessions[user.uid]).map(_session => {
                if (_session.endTimestamp) {
                    if (time1 == null) {
                        time1 = convertTimestampToSeconds(_session?.endTimestamp - _session?.startTimestamp)
                    } else {
                        time2 = convertTimestampToSeconds(_session?.endTimestamp - _session?.startTimestamp)
                    }
                    if (time1 && time2) {
                        totalTime = sumOFHoursWorked(time1, time2)
                        time1 = totalTime
                        time2 = null
                    }
                }
            })
        }

        if (totalTime == null) {
            if (time1) {
                return time1
            } else {
                return "00:00"
            }
        } else {
            return totalTime
        }

    }


    const handleEditable = (user, ind) => {
        setEditable(ind)
        setPhoneNumber(user?.phoneNumber)
    }

    const handleDelete = (user) => {
        if(deleting || !user){
            return;
        }
        let confirmation = window.confirm("Do you really want to Delete?");
        if(confirmation){
            setDeleting(true)
            let deleteRef = httpsCallable(functions,
                "deleteUser"
            )
            deleteRef({userId:user.uid}).then(()=>{
                setDeleting(false)
            }).catch((err)=>{
                console.log("error in deleting user", err)
                setDeleting(false)
            })
        }
    }

    return (
        <div className={styles.allusersWrapper}>
            <div className={styles.header}>
                Latest Report
                <div className={styles.btnWrapper}>
                    <div onClick={handleUserForm} className={styles.addUser}>
                        Add User
                    </div>
                </div>
            </div>
            <div className={styles.usersWrappers}>
                <div className={styles.lineWrapperHeading}>
                    <div>Name</div>
                    <div>Passcode</div>
                    <div>Mobile Number</div>
                    <div>Task Time</div>

                </div>
                {dataVisible && dataVisible.filter(_user => _user.applicationId.includes(applicationId)).length > 0 ?
                    dataVisible.filter(_user => _user.applicationId.includes(applicationId)).map((user, index) => {
                        return (
                            <div key={index} style={{ position: "relative" }}><div className={styles.editBtn}>
                                {editable == index && phoneNumber ? <div onClick={() => handleUpdatePhoneNumber(user)} className={styles.doneBtn}>{loading ? <span className={styles.loader}></span> : "done"}</div> : <img onClick={() => handleEditable(user, index)} src={editBtn} />}
                                <img className={styles.deleteBtn} onClick={() => handleDelete(user)} src={deleteBtn} />
                            </div>
                                <div style={{ background: editable == index && phoneNumber ? "#fff" : "none" }} className={styles.lineWrapper}>
                                    <div onClick={() => handleShowUser(user)} >{user.first_name}</div>
                                    <div>{user.code}</div>
                                    {editable == index && phoneNumber ? <input value={phoneNumber} type="text" onChange={handlePhoneUpdate} className={styles.phoneInput} /> : <div>{user.phoneNumber}</div>}
                                    <div>{getTotalTimeTaken(user)}</div>
                                </div>
                            </div>
                        );
                    }) : <div className={styles.noUsers}>No Users</div>}
            </div>
            {showUserForm && (
                <div className={styles.modalWrapper}>
                    <div onClick={handleUserForm} className={styles.modalOverlay}></div>
                    <div className={styles.modal}>
                        <UserForm handleUserForm={handleUserForm} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Allusers;
