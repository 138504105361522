import React, { useContext, useState } from 'react'
import styles from "./index.module.scss"
import folder from "../../assets/images/folder-open.png"
import { AuthContext } from '../../context/authContext/authContextProvider'

const sideBarOptions = [
    {
        name: "User Activity Log",
        id: "userActivityLog",
        logo: (active) => {
            return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.6707 14.3L21.2707 19.3C21.1207 20.83 21.0007 22 18.2907 22H5.71074C3.00074 22 2.88074 20.83 2.73074 19.3L2.33074 14.3C2.25074 13.47 2.51074 12.7 2.98074 12.11C2.99074 12.1 2.99074 12.1 3.00074 12.09C3.55074 11.42 4.38074 11 5.31074 11H18.6907C19.6207 11 20.4407 11.42 20.9807 12.07C20.9907 12.08 21.0007 12.09 21.0007 12.1C21.4907 12.69 21.7607 13.46 21.6707 14.3Z" stroke={active ? "black" : "white"} stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M3.5 11.4303V6.28027C3.5 2.88027 4.35 2.03027 7.75 2.03027H9.02C10.29 2.03027 10.58 2.41027 11.06 3.05027L12.33 4.75027C12.65 5.17027 12.84 5.43027 13.69 5.43027H16.24C19.64 5.43027 20.49 6.28027 20.49 9.68027V11.4703" stroke={active ? "black" : "white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.42969 17H14.5697" stroke={active ? "black" : "white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        }
    },
    {
        name: "User Report",
        id: "userReport",
        logo: (active) => {
            return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5 18V7C3.5 3 4.5 2 8.5 2H15.5C19.5 2 20.5 3 20.5 7V17C20.5 17.14 20.5 17.28 20.49 17.42" stroke={active ? "black" : "white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.35 15H20.5V18.5C20.5 20.43 18.93 22 17 22H7C5.07 22 3.5 20.43 3.5 18.5V17.85C3.5 16.28 4.78 15 6.35 15Z" stroke={active ? "black" : "white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 7H16" stroke={active ? "black" : "white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 10.5H13" stroke={active ? "black" : "white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>


        }
    },
]




const Sidebar = ({ activeId, setActive }) => {
    const { user } = useContext(AuthContext)

    const handleSidebar = (id) => {
        setActive(id)
    }
    return (
        <div className={styles.sidebarWrapper}>
            <div className={styles.list}>
                {sideBarOptions.map(sidebar => {
                    if ((!user || user?.role == "user") && sidebar.id == "userReport") {
                        return
                    }
                    return <><div onClick={() => handleSidebar(sidebar.id)} style={{ background: activeId == sidebar.id ? "#3752f6" : "#fff", color: activeId !== sidebar.id ? "#707070" : "#fff" }} className={styles.item}>
                        {sidebar.logo(activeId !== sidebar.id)}{sidebar.name}</div>
                    </>
                })}
            </div>
        </div>
    )
}

export default Sidebar