import React, { useContext, useState } from 'react'
import styles from "./index.module.scss"
import logo from "../../assets/images/rajasthanlogo.png"
import punjabLogo from "../../assets/images/punjabLogo.png"
import { applicationIdType } from '../../constants/applicationConstants'
import { useSelector } from 'react-redux'
import { auth } from '../../firebase'
import { AuthContext } from '../../context/authContext/authContextProvider'

export const Navbar = ({ searchInput, setSearchInput }) => {
    const applicationId = useSelector(state => state.details.applicationId)
    const [showLogout, setShowLogout] = useState(false)

    const { user } = useContext(AuthContext)
    const handleInputChange = (e) => {
        setSearchInput(e.target.value)
    }


    const handleLogoutButton = () => {
        setShowLogout(prev => !prev)
    }

    const handleLogout = () => {
        auth.signOut()
    }

    return (
        <div className={styles.navbarWrapper}>
            <div className={styles.searchInput}>
                <div className={styles.svgBox}>
                    <svg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M22 22L20 20" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                    </svg>
                </div>
                <input type='text' placeholder='Type something...' value={searchInput} onChange={handleInputChange} />
            </div>
            <img src={applicationId == applicationIdType.rajasthan ? logo : punjabLogo} className={styles.logo} />
            <div onClick={handleLogoutButton} className={styles.userBox}>
                {user?.displayName.charAt(0).toUpperCase()}
            </div>
            {showLogout && <div className={styles.modalWrapper}>
                <div onClick={handleLogoutButton} className={styles.overlay}></div>
                <div className={styles.modal}>
                    <div onClick={handleLogout}>Sign Out</div>
                </div>
            </div>}
        </div>
    )
}
