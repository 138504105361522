import { getDatabase, ref, child, get, onValue } from "firebase/database";


export function getUserSessions(applicationId, userId) {
    return new Promise(async (response, reject) => {
        try {
            const dbRef = ref(getDatabase());
            get(child(dbRef, `${applicationId}/sessions/${userId}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    response(snapshot.val());
                } else {
                    response([])
                    console.log("No data available");
                }
            }).catch((error) => {
                reject(error);
            });

        } catch (error) {
            console.error(error)
            reject(error)
        }
    })
}


let unsubscribe = null

export function getAllSessions(applicationId, cb) {
    try {
        const dbRef = ref(getDatabase(), `${applicationId}/sessions/`);
        unsubscribe = onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                cb(snapshot.val());
            } else {
                cb([])
                console.log("No data available");
            }
        })

    } catch (error) {
        console.error(error)
        cb(false);
    }
}

export const detachSessionsListener = {
    unsubscribe
}